import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class BudgetAcceptUseRegisterEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings',[
            'budget_accept_client_cnpj_charge',
            'budget_accept_corporate_reason_charged',
            'budget_accept_cep',
            'budget_accept_public_place',
            'budget_accept_address_number',
            'budget_accept_neighborhood',
            'budget_accept_address_complement',
            'budget_accept_fk_state_id',
            'budget_accept_fk_city_id'
        ])
        if(value) {
            axios
                .get(`/api/v1/users/${this.globalProps.parentValues.budget_fk_user_id}`)
                .then((subRes: any) => {
                    var subData = subRes.data.item;
                    this.setFieldValue('budget_accept_client_cnpj_charge',subData?.user_cpf_cnpj)
                    this.setFieldValue('budget_accept_corporate_reason_charged',subData?.user_name)
                    this.setFieldValue('budget_accept_cep',subData?.user_cep)
                    this.setFieldValue('budget_accept_public_place',subData?.user_address_street)
                    this.setFieldValue('budget_accept_address_number',subData?.user_address_number)
                    this.setFieldValue('budget_accept_neighborhood',subData?.user_address_neighborhood)
                    this.setFieldValue('budget_accept_address_complement',subData?.user_address_complement)
                    this.setFieldValue('budget_accept_fk_state_id',subData?.user_fk_state_id)
                    this.setFieldValue('budget_accept_fk_city_id',subData?.user_fk_city_id)
                })
                .finally(() => {
                    this.setState('loadings',[])
                })
        }


    }
}