import React from "react";
import { Button, Checkbox, Icon, IconButton, Input, Notification, Panel, PanelGroup, Table, Loader, SelectPicker, DatePicker } from "rsuite";
import axios from "../../../utilities/axios";
import { ReportTransferRequest } from "../../reports/ReportTransferRequest";
const { Column, HeaderCell, Cell } = Table;

export class ReportCustom extends React.Component<any, {}> {
  public state: any = {
    projects: [],
    collectStore: [],
    requesterStore: [],
    types: [],
    store: null
  }

  findProjects = async () => {
    await axios
      .get(`api/v1/reports/projects`)
      .then((res) => {
          this.setState({ projects: res.data.data })
      })
      .catch((e) => console.log(e.message))
  }

  findCollectStore = async () => {
    await axios
      .get(`api/v1/reports/collect-store`)
      .then((res) => {
          this.setState({ collectStore: res.data.data })
      })
      .catch((e) => console.log(e.message))
  }

  findRequesterStore = async () => {
    await axios
      .get(`api/v1/reports/requester-store`)
      .then((res) => {
          this.setState({ requesterStore: res.data.data, store: res.data.store })
      })
      .catch((e) => console.log(e.message))
  }

  findTypes = async () => {
    await axios
      .get(`api/v1/reports/types`)
      .then((res) => {
          this.setState({ types: res.data.data })
      })
      .catch((e) => console.log(e.message))
  }

  // componentDidUpdate(prevState: any): void {
  //   if (prevState.store !== this.state.store) {
  //     this.setState({ store: this.state.store })
  //   }
  // }

  componentDidMount = () => {
    this.findProjects()
    this.findCollectStore()
    this.findRequesterStore()
    this.findTypes()
  }
  
  render() {
    const { requesterStore, collectStore, projects, store, types } = this.state
    return (
        <PanelGroup accordion>
          <Panel header={<b>Relatório Solicitação de Transfência</b>} collapsible style={{ background: "white", marginBottom: 5 }} bordered>
            {store !== null ? (
              <ReportTransferRequest
                projects={projects}
                collectStore={collectStore}
                requesterStore={requesterStore}
                types={types}
                store={store}
              />
            ) : (
              <div style={{ display: "flex", alignItems: 'center', justifyContent: "center" }}>
                <Loader size="lg" content="Carregando..." />
              </div>
            )}
          </Panel>
        </PanelGroup>
    )
  }
}