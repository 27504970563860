import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { UserContext } from "../../components/authProvider/main";


export class BudgetEvents extends Event {
    static contextType = UserContext;

    public onLoad = (value: any, setField: any, setState: any, formValues: any) => {
        const { data: user } = this.userContext;

        // // Preenchimento das datas
        // if (this.globalProps.values?.budget_fk_status_budget_id == "8" && !this.globalProps.values?.budget_process_shipping) {
        //     this.setFieldValue("budget_process_shipping", dayjs().format("DD/MM/YYYY"));
        // }
        // if (this.globalProps.values?.budget_fk_status_budget_id == "9" && !this.globalProps.values?.budget_start_trip) {
        //     this.setFieldValue("budget_start_trip", dayjs().format("DD/MM/YYYY HH:mm"));
        // }
        // if (this.globalProps.values?.budget_fk_status_budget_id == "12" && !this.globalProps.values?.budget_finish_trip) {
        //     this.setFieldValue("budget_finish_trip", dayjs().format("DD/MM/YYYY HH:mm"));
        // }
        // if (this.globalProps.values?.budget_fk_status_budget_id == "12" && !this.globalProps.values?.budget_traffic_finish) {
        //     this.setFieldValue("budget_traffic_finish", dayjs().format("DD/MM/YYYY"));
        // }

        const valuesChargingType = this.globalProps.values?.charging_types
        const valuesBudgetPChargingDescription = this.globalProps.values?.budget_fk_charging_description_id

        if (valuesChargingType && this?.globalProps?.isEdit) {
            if(![null, undefined, ''].includes(this.globalProps?.values?.budget_merchandise_weight)){
                this.setField("budget_is_anvisa", {
                    readonly:false
                })
        
                this.setField("budget_is_imo", {
                    readonly:false
                })
        
                this.setField("budget_is_stackable", {
                    readonly:false
                })        
            }
            if (valuesChargingType.charging_type_is_reference_hbl == true) {
                this.setField('budget_reference_hbl', { required: true })
                this.setField('budget_reference_reserv', { hidden: true })
            }
            if (valuesChargingType.charging_type_is_reference_reservation == true) {
                this.setField('budget_reference_reserv', { required: true })
                this.setField('budget_reference_hbl', { hidden: true })
            }
        }

        if (user?.user_fk_role_id == 2) {
            this.setField('budget_process_shipping', {
                readonly: false,
            })
            this.setField('budget_start_trip', {
                readonly: false,
            })
            this.setField('budget_finish_trip', {
                readonly: false,
            })
            this.setField('budget_traffic_finish', {
                readonly: false,
            })
            this.setField('budget_date_finished', {
                readonly: false,
            })
        }

        if (valuesBudgetPChargingDescription && this?.globalProps?.isEdit) {
            if (valuesBudgetPChargingDescription == 7) {
                this.setField('budget_fk_chemical_class_id', {
                    readonly: false,
                    required: true
                })
            }
        }

        // if (this.globalProps.values?.budget_is_anvisa == true || this.globalProps.values?.budget_is_imo == true) {
        if (this.globalProps.values?.budget_is_imo == true) {
            this.setField("budget_fk_chemical_class_id", {
                readonly: false,
                required: true
            })
        }

        this.setState("loadings", ['budget_fk_user_id', 'budget_seller_fk_user_id'])
        this.setState("loadings", ['budget_fk_user_id', 'budget_seller_fk_user_id']);

        if (user?.user_fk_role_id == 6) {
            this.setFieldProperty("budget_fk_user_id", "readonly", true)
            this.setFieldValue("budget_fk_user_id", user?.user_id);
            this.setFieldProperty("budget_seller_fk_user_id", "readonly", true)
            this.setFieldValue("budget_cpfcnpj", user?.user_cpf_cnpj);
            this.setFieldValue("budget_client_name", user?.user_name);
            this.setFieldValue("budget_client_email", user?.user_email);

            if (user?.user_seller_fk_user_id) {
                this.setFieldValue("budget_seller_fk_user_id", user.user_seller_fk_user_id);
            }
        }

        if (user?.user_fk_role_id == 4) {
            this.setFieldValue("budget_seller_fk_user_id", user.user_id);
            this.setFieldProperty("budget_seller_fk_user_id", "readonly", true)
        }
        if (!this?.globalProps?.isEdit) {
            axios.get("/api/v1/budgets/latest").then((res: any) => {
                this.setFieldValue(
                    "budget_code", "BRAS" + ("000000" + res?.data?.next).slice(res?.data?.next?.toString().length) + "/" + (dayjs().get('year')).toString().slice(2)
                );

            })
            this.setFieldValue("budget_fk_status_budget_id", 1)
        }

        this.setState("loadings", [])
    }

}