import dayjs from "dayjs";

const locale = {
    sunday: 'Dom',
    monday: 'Seg',
    tuesday: 'Ter',
    wednesday: 'Qua',
    thursday: 'Qui',
    friday: 'Sex',
    saturday: 'Sab',
    ok: 'OK',
    today: 'Hoje',
    hours: 'Horas',
    minutes: 'Minutos',
    seconds: 'Segundos',
    yesterday: 'Ontem',
    last7Days: 'Ultimos 7 dias',
    formatDate: (date: any) => dayjs(date).format('DD/MM/YYYY')
};

const localeMonths = {
    ok: 'OK',
    formatDate: (date: any) => dayjs(date).format('MM/YYYY')
};

export { locale };
export { localeMonths };