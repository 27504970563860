import axios from "../../../utilities/axios";
import { Event } from "../../Event";

export class BudgetAcceptCepEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'budget_accept_public_place',
            'budget_accept_address_number',
            'budget_accept_neighborhood',
            'budget_accept_address_complement',
            'budget_accept_fk_state_id',
            'budget_accept_fk_city_id'
        ]);

        axios
            .get("https://viacep.com.br/ws/" + value + "/json/")
            .then(async (res: any) => {
                // console.log("consulta cep", res.data);

                if (res.data) {
                    this.setFieldValue("budget_accept_public_place", res.data.rua);
                    this.setFieldValue("budget_accept_neighborhood", res.data.bairro);

                    var stateId: any = undefined;
                    await axios
                        .get("/api/v1/states/select", {
                            params: {
                                state: res.data.uf
                            },
                        })
                        .then(async (res: any) => {
                            const { items } = res.data;
                            const { label, value } = items[0] ?? undefined;
                            stateId = value;
                            if (label && value) {
                                this.setFieldValue("budget_accept_fk_state_id", { label, value: value.toString() });
                            }
                            axios
                                .get("/api/v1/cities/select", {
                                    params: {
                                        state: stateId,
                                        city_ibge_code: res.data.ibge
                                    },
                                })
                                .then((res) => {
                                    var { items } = res.data;
                                    var { label, value } = items[0] ?? undefined;
                                    if (label && value) {
                                        this.setFieldValue("budget_accept_fk_city_id", value);
                                    }
                                })
                        })
                        this.setFieldValue("user_address_street", res.data.logradouro);
                        this.setFieldValue("user_address_neighborhood", res.data.bairro);
                }
            })
            .catch((e) => {
                console.log('Error :::> ', e)
            })
            .finally(() => {
                setState("loadings", []);
            });

    }
}