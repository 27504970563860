import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";

export class TransferRequestNfNumberEvents extends Event {
  onBlur = (value: any, setField: any, setState: any, formValues: any) => {
    // const valueFinal = ("000000000" + value).slice(-11)  
    const formattedValue = ("000000000" + value).slice(-9).replace(/(\d{3})/g, '$1 ').trim();
    this.setFieldValue("transfer_request_nf_number", formattedValue);


  }
} 