import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { Notification } from "rsuite";

let oldParamValues: any = {}

const budgetCalculate = async (props: any) => {

    props.setState("loadings", ['budget_subtotal', 'budget_icms', 'budget_total'])
    let paramField = props.values?.budget_fk_param_id
    if (!paramField) {
        Notification.error({ title: "Erro ao calcular!", description: "O campo Origem/Destino deve estar preenchido!", key: "errocalculo" })
        return
    }

    if (!oldParamValues?.[paramField]) {
        await axios.get("/api/v1/params/" + paramField).then((res) => {
            oldParamValues[paramField] = res.data.item
        }).catch(() => { })
    }

    let paramData = oldParamValues[paramField]
    //calcular subtotal
    let calcICMS
    let subTotal = parseFloat(((props?.values?.budget_shipping_cubed_weight || 0) +
        (props?.values?.budget_merchandise_dispatch || 0) +
        (props?.values?.budget_toll || 0) +
        (props?.values?.budget_risk_management || 0) +
        (props?.values?.budget_stacking || 0) +
        (props?.values?.budget_ad_valorem || 0) +
        (props?.values?.budget_imo || 0) +
        (props?.values?.budget_anvisa || 0)).toFixed(2));



    // calcular icmsƒ
    if (props?.values?.budget_fk_charging_type_id && subTotal) {
        if (paramData?.param_icms) {
            calcICMS = (subTotal / (paramData?.param_icms / 100))
            calcICMS = calcICMS - subTotal
            props.setFieldValue("budget_icms", calcICMS)
        }
    }
    let calculo
    //calcular subtotal / total / ICMS
    if (paramData?.param_icms) {
        if (!calcICMS || isNaN(calcICMS)) {
            calcICMS = 0
        }
        calculo = subTotal + calcICMS;

        props.setFieldValue("budget_subtotal", subTotal ?? 0)
        props.setFieldValue("budget_total", calculo ?? 0)
    }

    // return {
    //     subtotal: subTotal,
    //     icms: calcICMS,
    //     total: calculo
    // }
    setTimeout(() => {
        props.setState("loadings", [])
    }, 5000)


}
export class BudgetWmValueEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        // budgetCalculate(this)
    }
}
export class BudgetFkParamIdEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        // if (value) {
        //     console.log("entoru value onchahfan param", value)
        //     this.setField('budget_fk_param_id', { readonly: false });
        // }

        // axios.get("/api/v1/params/" + value).then((res) => {
        //     const paramChargingTypes = res.data?.item?.param_charging_types;
        //     console.log(paramChargingTypes);
        //     console.log("budget_fk_charging_type_id", formValues.budget_fk_charging_type_id);
        //     if (paramChargingTypes.includes(parseInt(formValues.budget_fk_charging_type_id))) {
        //         console.log("inclui");
        //     }
        // });

        // budgetCalculate(this)
    }
}

export class BudgetIsAnvisaEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ['budget_anvisa', 'budget_is_anvisa', 'budget_fk_chemical_class_id'])
        if (value == true) {

            // this.setField("budget_fk_chemical_class_id", {
            //     readonly: false,
            //     required: true
            // })

            this.setField("budget_customer_reference", {
                width: 2
            })

            let paramField = this?.values?.budget_fk_param_id
            if (!paramField) {
                Notification.error({ title: "Erro ao calcular!", description: "O campo Origem/Destino deve estar preenchido!", key: "errocalculo" })
                return
            }

            if (!oldParamValues?.[paramField]) {
                await axios.get("/api/v1/params/" + paramField).then((res) => {
                    oldParamValues[paramField] = res.data.item
                }).catch(() => { })
            }

            let paramData = oldParamValues[paramField]

            if (![null, undefined, ''].includes(this.globalProps.values?.budget_shipping_cubed_weight)) {
                this.setFieldValue("budget_anvisa", (this.globalProps?.values?.budget_shipping_cubed_weight * (paramData?.param_anvisa / 100)))
            } else {
                Notification.error({ title: "Erro ao calcular!", description: "O campo Frete por W/M deve estar preenchido!" })
                this.setFieldValue("budget_is_anvisa", false)
            }

        }


        setTimeout(async () => {
            await budgetCalculate(this)
        }, 2000)

        if (value == false) {
            this.setFieldValue("budget_fk_chemical_class_id", undefined)

            this.setField("budget_fk_chemical_class_id", {
                readonly: true,
                required: false
            })

            this.setField("budget_customer_reference", {
                width: 3
            })
            
        }

        setTimeout(() => {
            this.setState("loadings", "")
        }, 2000)
    }
}

export class BudgetIsImoEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ['budget_is_imo', 'budget_imo', 'budget_fk_chemical_class_id'])
        if (value == true) {

            this.setField("budget_fk_chemical_class_id", {
                readonly: false,
                required: true
            })

            let paramField = this?.values?.budget_fk_param_id
            if (!paramField) {
                Notification.error({ title: "Erro ao calcular!", description: "O campo Origem/Destino deve estar preenchido!", key: "errocalculo" })
                return
            }

            if (!oldParamValues?.[paramField]) {
                await axios.get("/api/v1/params/" + paramField).then((res) => {
                    oldParamValues[paramField] = res.data.item
                }).catch(() => { })
            }

            let paramData = oldParamValues[paramField]

            if (![null, undefined, ''].includes(this.globalProps.values?.budget_shipping_cubed_weight)) {
                this.setFieldValue("budget_imo", this.globalProps.values?.budget_shipping_cubed_weight * (paramData?.param_imo / 100))
            } else {
                Notification.error({ title: "Erro ao calcular!", description: "O campo Frete por W/M deve estar preenchido!" })
                this.setFieldValue("budget_is_imo", false)
            }


        }

        if (value == false) {
            this.setFieldValue("budget_fk_chemical_class_id", undefined)

            this.setField("budget_fk_chemical_class_id", {
                readonly: true,
                required: false
            })
        }
        budgetCalculate(this)
        setTimeout(() => {
            this.setState("loadings", [])
        }, 5000)
    }
}

export class BudgetIsStackableEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {

        this.setState("loadings", ['budget_stacking', 'budget_is_stackable'])
        if (value == 1) {
            Notification.warning({
                title: "Atenção!",
                description: "Durante a operação, caso identificado impossibilidade de remonte será cobrado taxa extra referente a NÃO Empilhamento"
            })
            this.setFieldValue("budget_stacking", 0)

        }
        if (value == 2) {

            let paramField = this?.values?.budget_fk_param_id
            if (!paramField) {
                Notification.error({ title: "Erro ao calcular!", description: "O campo Origem/Destino deve estar preenchido!", key: "errocalculo" })
                return
            }

            if (!oldParamValues?.[paramField]) {
                await axios.get("/api/v1/params/" + paramField).then((res) => {
                    oldParamValues[paramField] = res.data.item
                }).catch(() => { })
            }

            let paramData = oldParamValues[paramField]

            if (![null, undefined, ''].includes(this.globalProps.values?.budget_shipping_cubed_weight)) {
                this.setFieldValue("budget_stacking", this.globalProps.values?.budget_shipping_cubed_weight * (paramData?.param_stackable / 100))
            } else {
                Notification.error({ title: "Erro ao calcular!", description: "O campo Frete por W/M deve estar preenchido!" })
                this.setFieldValue("budget_is_stackable", '')
            }


        }
        budgetCalculate(this)
        setTimeout(() => {
            this.setState("loadings", "")
        }, 5000)
    }
}

export class BudgetMerchandiseValueEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {
        // budgetCalculate(this)
        this.setState("loadings", ['budget_merchandise_dispatch', 'budget_toll', 'budget_shipping_cubed_weight',
            'budget_merchandise_tax_suspended_value', 'budget_risk_management'])

        let paramField = this?.values?.budget_fk_param_id
        if (!paramField) {
            Notification.error({ title: "Erro ao calcular!", description: "O campo Origem/Destino deve estar preenchido!", key: "errocalculo" })
            return
        }

        if (!oldParamValues?.[paramField]) {
            await axios.get("/api/v1/params/" + paramField).then((res) => {
                oldParamValues[paramField] = res.data.item
            }).catch(() => { })
        }

        let paramData = oldParamValues[paramField]
        if (paramData?.param_dispatch) {
            //seta valor despacho
            this.setFieldValue("budget_merchandise_dispatch", paramData?.param_dispatch);
        }
        if (paramData?.param_toll) {
            //seta valor pedagio
            this.setFieldValue("budget_toll", paramData?.param_toll);
        }

        this.setFieldValue("budget_merchandise_tax_suspended_value", value * (paramData?.param_suspended_taxes))

        setTimeout(() => {
            this.setFieldValue("budget_risk_management", ((value + this.globalProps.values?.budget_merchandise_tax_suspended_value)) * (paramData?.param_gris / 100))

            this.setFieldValue("budget_ad_valorem", ((value + this.globalProps.values?.budget_merchandise_tax_suspended_value)) * (paramData?.param_ad_valorem / 100))
        }, 1000)



        budgetCalculate(this)

        setTimeout(() => {
            this.setState("loadings", "")
        }, 5000)
    }
}

export class BudgetMerchandiseWeightEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        // budgetCalculate(this)
    }
}

export class BudgetMerchandiseCubageEvents extends Event {
    public onBlur = async (value: any, setField: any, setState: any, formValues: any) => {

        const oldValueCubage = value

        this.setState("loadings", ['budget_shipping_cubed_weight'])

        let paramField = this?.values?.budget_fk_param_id
        if (!paramField) {
            Notification.error({ title: "Erro ao calcular!", description: "O campo Origem/Destino deve estar preenchido!", key: "errocalculo" })
            return
        }

        if (!oldParamValues?.[paramField]) {
            await axios.get("/api/v1/params/" + paramField).then((res) => {
                oldParamValues[paramField] = res.data.item
            }).catch(() => { })
        }

        let paramData = oldParamValues[paramField]

        this.setFieldValue("budget_merchandise_base_value", this.globalProps.values?.budget_merchandise_weight / paramData?.param_disparity)

        if (this.globalProps.values?.budget_merchandise_base_value < oldValueCubage) {
            this.setFieldValue("budget_merchandise_base_value", oldValueCubage)
            this.setFieldValue("budget_shipping_cubed_weight", paramData?.param_cubic_weight * oldValueCubage)
        } else {
            this.setFieldValue("budget_merchandise_base_value", this.globalProps.values?.budget_merchandise_weight / paramData?.param_disparity)
            this.setFieldValue("budget_shipping_cubed_weight", paramData?.param_cubic_weight * this.globalProps.values?.budget_merchandise_base_value)
        }
        budgetCalculate(this)

        this.setField("budget_is_anvisa", {
            readonly:false
        })

        this.setField("budget_is_imo", {
            readonly:false
        })

        this.setField("budget_is_stackable", {
            readonly:false
        })
        
        setTimeout(() => {
            this.setState("loadings", "")
        }, 5000)
    }
}

export class BudgetMerchandiseDispatchEvents extends Event {
    public onBlur = (value: any, setField: any, setState: any, formValues: any) => {
        // budgetCalculate(this)
    }
}