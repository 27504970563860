import React from "react";
import Formalize from "../../../Formalize";
import { Button, Modal, Notification } from "rsuite";
import _ from "lodash";
import axios from "../../../../utilities/axios";
import { UserContext } from "../../../../components/authProvider/main";

interface BudgetAcceptCustomProps {
    ButtonOpenName: string
    parentValues?: any
    parent?: any
    formOverrides?: any
    onSubmit?: any
    valuesProps: any
    icon: any
}

interface BudgetAcceptCustomState {
    showModal: boolean;
    values: any; // Definindo como 'any'
    form: any;   // Definindo como 'any'
    loading: boolean;
}

export class BudgetAcceptCustom extends React.Component<BudgetAcceptCustomProps, {}> {
    static contextType = UserContext;

    state: BudgetAcceptCustomState = {
        showModal: false,
        values: {},
        form: {},
        loading: true
    }

    public elementRef: any = null;

    componentDidMount(): void {
        this.elementRef = React.createRef();
        const api = 'budget_accepts';
        axios
            .get(`/api/v1/${api}/fields`)
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }
                    this.setState({ form: fields });
                }
            })
        axios
            .get(`/api/v1/${api}/items/get`,
                {
                    params: {
                        budget_id: this.props.valuesProps.formValues?.budget_id
                    }
                }
            )
            .then((res) => {
                if (res.data.status === 200) {
                    this.setState({ values: res.data.items })
                }
            })
    }

    onOpen = () => {

    }


    onClose = () => {

    }


    onSubmit = async (valuesSubmit: any) => {
        const valuesWithIdBudget = { ...valuesSubmit, budget_accept_fk_budget_id: this.props.valuesProps.formValues?.budget_id }
        // for(const fieldName in valuesSubmit) {
        //     const value = valuesSubmit[fieldName];
        //     const field = this.state.form[fieldName];
        //     console.log({value})
        // }

        this.setState({ loading: true })
        this.props.onSubmit(
            async () => {
                await axios
                    .post('/api/v1/budget_accepts/insert',
                        {
                            data: valuesWithIdBudget
                        }
                    )
                    .then((response) => {
                        Notification.success(
                            {
                                title: 'Sucesso',
                                description: response.data.message
                            }
                        )

                    })
            }
        )

        axios
            .post('/api/v1/budget_accepts/insert',
                {
                    data: valuesWithIdBudget
                }
            )
            .then((response) => {
                Notification.success(
                    {
                        title: 'Sucesso',
                        description: response.data.message
                    }
                )

            })
    }



    // renders

    renderOnSubmitButtons = (onSubmitEvent: any) => {
        // console.log("This ::>", this)
        return (
            <div className="col-md-12" style={{ marginBottom: 10 }}>
                <Button
                    onClick={() => {
                        sessionStorage.removeItem("currentParent");
                        sessionStorage.removeItem("currentParentIndex");
                        this.setState({ showModal: false });
                    }}>
                    <i className="fas fa-fw fa-times mr-2"></i>
                    Cancelar
                </Button>
                {/* {this.state.formSolucao_isEdit !== undefined && this.state.formSolucao_isRead === false && ( } */}
                {
                    (this.context.data.user_fk_role_id !== 6 ||
                        ![10, 5, 6, 8, 9, 12, 13, 14].includes(this.props.valuesProps.formValues.budget_fk_status_budget_id)) && (
                        <Button
                            color="green"
                            onClick={() => {
                                sessionStorage.removeItem("currentParent");
                                sessionStorage.removeItem("currentParentIndex");
                                if (onSubmitEvent) {
                                    onSubmitEvent();
                                }
                            }}>
                            <i className="fas fa-fw fa-save mr-2"></i>
                            Gravar
                        </Button>
                    )
                }
            </div>

        )
    }

    // this.state.showModal

    render(): React.ReactNode {
        return (
            <>

                <Modal
                    size="lg" className="rs-modal-full" show={this.state.showModal} onHide={() => this.setState({ showModal: false })}
                >
                    {Object.keys(this.state.form).length > 0 && (
                        <Formalize
                            isModal={true}
                            readonly={false}
                            parentValues={this.props.valuesProps.formValues}
                            parent={this.props.parent}
                            values={_.cloneDeep(this.state.values)}
                            onSubmit={this.onSubmit}
                            submitButton={this.renderOnSubmitButtons}
                            form={this.state.form}
                            name={"this.props.field.api"}
                        />
                    )}
                </Modal>

                <Button onClick={() => this.setState({ showModal: true })} color={"blue"} className="ml-4" style={
                    {
                        display: 'flex',
                        flex: 'row',
                        gap: 10,
                        justifyContent: 'center',
                        alignItems: 'center'
                    }
                }>
                    {this.props.icon}
                    {this.props.ButtonOpenName ? this.props.ButtonOpenName : ''}
                </Button>
            </>
        )
    }
}