import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col } from "rsuite";
import { Event } from "../Event";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";


class ParamFooterComponent extends React.Component<any, {}> {
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituation: false,
        situacaoModal: "",
        started: false,
        loadingClockIn: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false
    };

    onCloneParam = () => {
        if(this.props?.values?.formValues?.param_id){
            this.setState({ loading: true })
            axios.get("/api/v1/params/duplicate/"+this.props?.values?.formValues?.param_id).then((res)=>{
                Notification.success({
                    title: "Serviço Clonado!",
                    description: `O Serviço #${this.props?.values?.formValues?.param_id} Foi Clonado com Sucesso!`
                })
                this.props.history.push("/dashboard/configuracoes/params/"+res.data)
            }).catch((e)=>{
                throw("Erro ao Clonar Parametro")
            })
        }else{
            throw("Erro ao Clonar Parametro")
        }
    }

    render() {
        return (
            <div>
                <Button loading={this.state.loading} onClick={() => this.onCloneParam()} color={"blue"} className="ml-4">
                    <i className="fas fa-copy fa-fw mr-2"></i>
                    Clonar Parametro
                </Button>
            </div>
        );
    }
}

const ParamFooterWithRouter = withRouter(ParamFooterComponent);

export class ParamFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <ParamFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}
