import axios from "../../utilities/axios";
import React from "react";
import { Button, Notification, Input, SelectPicker, Col, Whisper, InputGroup, Tooltip, Modal, Placeholder, InputNumber, FormGroup, ControlLabel, HelpBlock } from "rsuite";
import { Event } from "../Event";
import { UserContext } from "../../components/authProvider/main";
import { withRouter } from "react-router";
import { ConfirmModal } from "../../components/confirmModal/ConfirmModal";
import { CustomModal } from "../../components/customModal/CustomModal"
import classNames from "classnames";
import { link } from "fs";
import { MailerModal } from "../../components/mailer/MailerModal";
import { Editor } from "react-draft-wysiwyg";
import { WYSIWYG } from "../../components/formfields/WYSIWYG";
import { RefuseClientModal } from "../../components/customModal/RefuseClientModal";
import { CancelClientModal } from "../../components/customModal/CancelClientModal";

var userInfoRole: any = '';
var userInfoId: any = '';

class TransferRequestFooterComponent extends React.Component<any, {}> {
    static contextType?: React.Context<any> | undefined = UserContext;
    public state = {
        show: false,
        showEmail: false,
        loading: false,
        showSituationPsms: false,
        showSituationTypeLiberation: false,
        showSituationRm: false,
        situacaoModal: "",
        started: false,
        loadingAccepted: false,
        reproveShowSituation: false,
        reprovetionText: 'sem valor',
        showDisclaimModal: false,
        status: null,
        openModal: false,
        nfNumber: null,
        quantities: null,
        acceptedButtonLoading: false,
        openModalRefuse: false,
        openModalCancelClient: false,
        showDonateClient: false,
        showAcceptOrCancel: false
    };

    handleOpen = () => {
        this.setState({
            openModal: true
        })
    }

    handleClose = () => {
        this.setState({
            openModal: false
        })
    }






    cancelTransfer = () => {
        this.setState({ loading: true })
        if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_observations)) {
            if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id)) {
                this.props.onSubmit(
                    () => {
                        axios
                            .post(`/api/v1/transfer-request`, { id: this.props.values.formValues.transfer_request_id })
                            .then((res) => {
                                if (res.status == 200) {
                                    Notification.success({
                                        title: "Sucesso!",
                                        description: 'Transferência cancelada com sucesso!'
                                    })

                                    this.setState({ loading: false })
                                }
                            }).catch((e) => {
                                console.log("Erro ao consultar o status: ", e)
                            }).finally(() => {
                                this.setState({ loading: false })
                            })
                    },
                    () => {
                        this.setState({ loading: false });
                    })
            } else {
                Notification.warning({
                    title: "Atenção!",
                    description: 'Divergência com a situação, contate um Administrador',
                    duration: 2500
                });
                this.setState({ loading: false })
            }
        } else {
            Notification.warning({
                title: "Atenção!",
                description: 'Por favor preencher o motivo da Recusa!',
                duration: 2500
            });
            this.setState({ loading: false })
        }
    }


    cancelTransferRequest = () => {
        this.setState({ loading: true });
        if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_observations)) {
            if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id)) {
                this.props.onSubmit(
                    () => {
                        axios
                            .post(`/api/v1/order-refuse-request`, { id: this.props.values.formValues.transfer_request_id })
                            .then((res) => {
                                if (res.status == 200) {
                                    Notification.success({
                                        title: "Sucesso!",
                                        description: 'Transferência cancelada com sucesso!'
                                    });
                                    this.setState({ loading: false })
                                }
                            }).catch((e) => {
                                console.log("Erro ao consultar o status: ", e)
                            }).finally(() => {
                                this.setState({ loading: false })
                            })
                    })
            } else {
                Notification.warning({
                    title: "Atenção!",
                    description: 'Divergência com a situação, contate um Administrador!',
                    duration: 2500
                });
                this.setState({ loading: false })
            }
        } else {
            Notification.warning({
                title: "Atenção!",
                description: 'Por favor preencher o motivo do Cancelamento',
                duration: 2500
            });
            this.setState({ loading: false })
        }
    }


    acceptedOrder = async () => {
        this.setState({ loadingAccepted: true })

        if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_nf_number)) {
            if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_serie)) {
                if (!['', undefined, null].includes(this.props.values.formValues.transfer_request_quantity_volumes)) {
                    this.props.onSubmit(
                        () => {
                            axios
                                .post(`/api/v1/order-accepted`, { id: this.props.values.formValues.transfer_request_id, nf: this.props.values.formValues.transfer_request_nf_number })
                                .then((res) => {
                                    if (res.status === 200) {
                                        Notification.success({
                                            title: "Sucesso!",
                                            description: 'Pedido aceito!'
                                        });
                                    }
                                    return;
                                }).catch((e) => {
                                    Notification.error({
                                        title: "Erro!",
                                        description: 'Não foi possível aceitar o pedido',
                                        duration: 2500
                                    });
                                    this.setState({ loadingAccepted: false })
                                    return;
                                })
                                .finally(() => {
                                    this.setState({ loadingAccepted: false })
                                })
                        })
                } else {
                    Notification.warning({
                        title: "Atenção!",
                        description: 'Por favor preencher o campo de Quantidade de Volumes',
                        duration: 2500
                    });
                    this.setState({ loadingAccepted: false })
                }
            } else {
                Notification.warning({
                    title: "Atenção!",
                    description: 'Por favor preencher o campo de Série',
                    duration: 2500
                });
                this.setState({ loadingAccepted: false })
            }
        } else {
            Notification.warning({
                title: "Atenção!",
                description: 'Por favor preencher o campo de Número NF',
                duration: 2500
            });
            this.setState({ loadingAccepted: false })
        }
    }

    createServiceVuupt = async () => {
        const { nfNumber, quantities } = this.state;
        this.setState({ acceptedButtonLoading: true })
        if (nfNumber === null) {
            Notification.warning({
                title: "Aviso!",
                description: 'Por favor, informe o número da NF!',
                duration: 2500
            });
            this.setState({ acceptedButtonLoading: false })
        } else if (quantities === null) {
            Notification.warning({
                title: "Aviso!",
                description: 'Por favor, informe a quantidade de volumes!',
                duration: 2500
            });
            this.setState({ acceptedButtonLoading: false })
        } else {
            await axios.post('/api/v1/create-service', { idTransferRequest: this.props.values.formValues.transfer_request_id, nf: nfNumber, quantities: quantities })
                .then((res) => {
                    if (res.data.status == 200 || res.data.status == 201) {
                        Notification.success({
                            title: "Sucesso!",
                            description: 'Pedido aceito com sucesso!',
                        });
                        this.handleClose()
                        setTimeout(() => {
                            this.props.history.push("/dashboard/configuracoes/transfer_requests")
                        }, 2000)
                    } else {
                        Notification.error({
                            title: "Erro!",
                            description: 'Falha ao aceitar a transfêrencia!',
                            duration: 2500
                        });
                    }
                })
                .catch((e) => {
                    console.log("Erro na requisição: ", e)
                })
                .finally(() => this.setState({ acceptedButtonLoading: false }))
        }
    }

    render() {
        return (<>
        {/* {console.log("this.props", this.props.values.formValues.transfer_request_fk_user_id)} */}
            
            {[1].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id)
                && [userInfoId].includes(this.props.values.formValues.transfer_request_collect_fk_user_id)
                && (
                    <Button loading={this.state.loadingAccepted} onClick={() => {
                        this.acceptedOrder();
                    }} color="blue" className="ml-2">
                        <i className="fas fa-window-close fa-fw mr-2"></i>
                        Aceitar do Cliente Doador
                    </Button>
                )}
            {
                [1].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id)
                && [userInfoId].includes(this.props.values.formValues.transfer_request_collect_fk_user_id)
                && (
                    <Button loading={this.state.loading}
                        onClick={() => {
                            this.cancelTransfer();
                        }}
                        // onClick={this.cancelTransferRequest} 
                        color="red" className="ml-4">
                        <i className="fas fa-window-close fa-fw mr-2"></i>
                        Cancelar do Cliente Doador
                    </Button>
                )
            }
            {
                [1].includes(this.props.values.formValues.transfer_request_fk_status_transfer_request_id)
                && [userInfoId].includes(this.props.values.formValues.transfer_request_fk_user_id)
                && (
                    <Button loading={this.state.loading}
                        onClick={() => {
                            this.cancelTransferRequest();
                        }}
                        // onClick={this.cancelTransferRequest} 
                        color="red" className="ml-4">
                        <i className="fas fa-window-close fa-fw mr-2"></i>
                        Cancelar do Cliente Solicitante
                    </Button>
                )
            }

        </>)
    }

}

const getUserInfo = axios.get("/api/v1/me")
    .then(response => {
        userInfoRole = response?.data?.user?.user_fk_role_id
        userInfoId = response?.data?.user?.user_id

    })
    .catch(error => {
        console.error("Erro ao fazer a requisição:", error);
    });

const TransferRequestFooterWithRouter = withRouter(TransferRequestFooterComponent);

export class TransferRequestFooterEvents extends Event {
    public onRenderEditFooter = (onSubmit: any) => {
        return (
            <>
                <TransferRequestFooterWithRouter onSubmit={onSubmit} values={this.globalState} />
            </>
        );
    };
}