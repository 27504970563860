// budget_fk_charging_type_id

import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class BudgetFkChargingTypeIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState("loadings", ['budget_reference_hbl', 'budget_reference_reserv'])
        
        this.setField('budget_reference_reserv', { required: false, hidden: true })
        this.setField('budget_reference_hbl', { required: false, hidden: true })

        await axios.get('/api/v1/budget/get-charging-types/', {
            params: {
                id: value
            }
        }).then((response: any) => {
            if (response?.status == 200) {
                const valuesChargingType = response.data.items
                if (valuesChargingType.charging_type_is_reference_hbl == true) {
                    this.setField('budget_reference_hbl', { required: true, hidden: false })
                }
                if (valuesChargingType.charging_type_is_reference_reservation == true) {
                    this.setField('budget_reference_reserv', { required: true, hidden: false })
                }
            }
            if (response?.status == 204) {
                this.setField('budget_reference_hbl', { required: false })
                this.setField('budget_reference_reserv', { required: false })
            }
        })
            .catch((e: any) => {
                this.setField('budget_reference_hbl', { required: false })
                this.setField('budget_reference_reserv', { required: false })
            }).finally(() => {
                setTimeout(() => {
                    this.setState("loadings", [])
                }, 3000)
            })

    }
}

