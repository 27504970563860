import React from "react";
import { Button, Modal, SelectPicker } from "rsuite";


interface BudgetActionsProps {
    changeSituation: ({nextSituation}:{nextSituation: number}) => void
}

export class BudgetActions extends React.Component<BudgetActionsProps, {}> {
    state = {
        selectActionData: [
            {
                value: 5,
                label: 'Documentos Validados'
            },
            {
                value: 6,
                label: 'DTA Parametrizada'
            },
            {
                value: 15,
                label: 'Processo Agendado'
            },
            {
                value: 8,
                label: 'Em Processo de Carregamento Programado'
            },
            {
                value: 9,
                label: 'Em Viagem'
            },
            {
                value: 12,
                label: 'Chegada no Destino'
            },
            {
                value: 14,
                label: 'Trânsito Concluido'
            },
            {
                value: 16,
                label: 'Concluído'
            }
        ],
        showModal: false,
        valueSelect: null
    }
    
    validChange = () => {
        if(this.state.valueSelect) {
            this.props.changeSituation({ nextSituation: this.state.valueSelect})
        }
    }
    
    render(): React.ReactNode {
        // {console.log("this:>", this)}
        return (
            <>
                <Modal
                    show={this.state.showModal}
                    onHide={() => this.setState({ showModal: false })}
                >
                    <Modal.Header>
                        Ações necessarias para mudar a situação do Orçamento
                    </Modal.Header>
                    <Modal.Body

                    >
                        <SelectPicker
                            block
                            data={this.state.selectActionData}
                            onChange={(value) => this.setState({ valueSelect: value })}
                            onClean={() => this.setState({ valueSelect: null })}
                        />
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="col-md-12" style={{ marginBottom: 10 }}>
                            <Button
                                onClick={() => this.setState({ showModal: false })}
                            >
                                <i className="fas fa-fw fa-times mr-2"></i>
                                Cancelar
                            </Button>
                            {/* {this.state.formSolucao_isEdit !== undefined && this.state.formSolucao_isRead === false && ( } */}
                            <Button
                                color="green"
                                onClick={this.validChange}
                            >
                                <i className="fas fa-angle-double-right mr-2"></i>
                                Mudar Situação
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
                <Button
                    color="blue"
                    style={
                        {
                            display: 'flex',
                            flex: 'row',
                            gap: 10,
                            justifyContent: 'center',
                            alignItems: 'center'
                        }
                    }
                    onClick={() => this.setState({ showModal: true })}
                >
                    <i className="fas fa-exclamation"></i>
                    Ações
                </Button>
            </>
        )
    }
}