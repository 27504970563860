import { Event } from "../Event";
import axios from "../../utilities/axios";

export class BudgetFkUserIdEvents extends Event {
    public onChange = (value: any, setField: any, setState: any, formValues: any) => {
        if (value) {
            axios.get(`/api/v1/users/${value}`).then((res: any) => {
                if (res?.data?.item?.user_id) {
                    const { user_cpf_cnpj, user_email, user_name } = res?.data?.item
                    this.setFieldValue("budget_cpfcnpj", user_cpf_cnpj)
                    this.setFieldValue("budget_client_name", user_name)
                    this.setFieldValue("budget_client_email", user_email)
                }
            })
        }
    }
}