import React from "react";
import { FormGroup, Notification, CheckTreePicker as RSuiteCheckTreePicker } from "rsuite";
import axios from "../../../../utilities/axios";
import { Placeholder } from 'rsuite';
import _ from "lodash";
const { Paragraph } = Placeholder;


export class CheckTreePickerUserRestrict extends React.Component<any, {}> {
    public state = {
        isLoading: false,
        data: [],
        dataValueChange: [],
        loadValues: [],
        valueProjectId: null
    }

    componentDidMount(): void {
        try {
            let arrayStringValues: any = [];
            if (this?.props.values.user_restricts) {
                if (this.props.values.user_restricts.includes(',')) {
                    const arrayOriginal = this.props.values.user_restricts.split(',');
                    arrayStringValues = arrayOriginal.map((item: any) => Number(item));
                } else {
                    arrayStringValues.push(parseInt(this.props.values.user_restricts))
                }
                this.setState({ loadValues: arrayStringValues })
            }
            if (this.props.values.user_id && ![0, '', null, undefined].includes(this.props.values.user_fk_project_id)) {
                this.onStartLoading()
            }
        } catch (e) {
            console.log('Error :::> ', e)
        }
    }


    onStartLoading = () => {
        axios.get('/api/v1/custom/checktreepicker/initial/select', {
            params: {
                crud: 'user_restricts',
                relationTable: 'users',
                relationTableId: this.props.values.user_id,
                paramsWhere: [
                    {
                        user_fk_project_id: this.props.values.user_fk_project_id
                    }
                ]
            }
        })
            .then((res) => {
                var arr: any = [...res.data.items];
                // if (value) {
                //     arr = this.find(value, arr);
                //     //(arr);
                // }
                this.setState({ data: arr, loading: false });
            })
    }

    onLoadingOptions = () => {
        this.setState({ isLoading: true });
        axios.get('/api/v1/users/restrict/select', {
            params: {
                project_id: this.props.values.user_fk_project_id,
                user_id: this.props.values?.user_id ? this.props.values.user_id : null
            }
        })
            .then((response) => {
                this.setState({ data: response.data.items })
            })
            .catch((err) => {
                Notification.info(
                    {
                        title: 'Info',
                        description: err.response.data.message
                    }
                )
            })
            .finally(() => {
                this.setState({ isLoading: false })
            })
    }

    selectUsers = (eventValue: any) => {
        let valueOnChange = [...this.state.loadValues, eventValue.value];
        if (!eventValue.check) {
            valueOnChange = valueOnChange.filter(item => item !== eventValue.value)
        }
        this.setState({ loadValues: valueOnChange });
        this.props.onChange(valueOnChange)
        // this.setState({ loadValues: valueOnChange})
    }

    onClean = () => {
        this.setState({ dataValueChange: [] });
        this.props.onChange([])
    }

    render() {
        return (
            <>
                {/* {!this.state.isLoading ? (
                    <FormGroup
                        style={
                            {
                                paddingTop: 4
                            }
                        }
                    >
                        <label>Centro de Custos Restrito</label>
                        <div className="rs-form-control-wrapper">
                            <RSuiteCheckTreePicker
                                data={[]}
                                onOpen={() => this.onLoadingOptions()}
                            />
                        </div>
                    </FormGroup>
                ) : (
                    <Paragraph />
                )} */}
                <FormGroup
                    style={
                        {
                            paddingTop: 4
                        }
                    }
                >
                    <label>Centro de Custos Restrito</label>
                    <div className="rs-form-control-wrapper">
                        <RSuiteCheckTreePicker
                            data={this.state.data}
                            value={this.state.loadValues}
                            onOpen={() => this.onLoadingOptions()}
                            onSelect={(value) => this.selectUsers(value)}
                            onClean={() => this.onClean()}
                        />
                    </div>
                </FormGroup>
            </>
        )
    }
}