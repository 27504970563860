import React from "react";
import { Table, Modal, Button, Loader, Panel, Notification, Alert, Placeholder } from "rsuite";
import axios from "../../utilities/axios";
import { FilterableCell } from "../customTable/FilterableCell";
import { SchemaToParsedCell } from "../customTable/SchemaToParsedCell";
import Formalize from "../Formalize";
import _ from "lodash";
import { InlineGrid } from "./InlineGrid";
import { SortableArray } from "../sortableArray/main";
import { CustomExtensions } from "../../custom/CustomExtensions";
import pluralize from "pluralize";
import { useDrop, useDrag, DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import checkPermissionPage from "../../utilities/checkPermissionPage";


const { Column, HeaderCell, Cell } = Table;



function DraggableCell({ children, onDrag, id, rowData, columnIdName, ...rest }: any) {
    const ref = React.useRef(null);
    const ItemTypes = {
        COLUMN: 'column',
        ROW: 'row'
    };

    const [{ canDrop, isOver }, drop] = useDrop({
        accept: ItemTypes.ROW,
        collect: (monitor: any) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop()
        }),
        drop(item: any, monitor: any) {
            onDrag && onDrag(item[`${columnIdName}`], rowData[`${columnIdName}`]);
        }
    });

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.ROW, // Definindo o tipo diretamente aqui
        item: { [`${columnIdName}`]: rowData[`${columnIdName}`] },
        collect: (monitor: any) => ({
            isDragging: monitor.isDragging()
        })
    });
    const opacity = isDragging ? 0 : 1;
    const isActive = canDrop && isOver;

    drag(drop(ref));

    const styles: React.CSSProperties = {
        display: 'flex',
        flexDirection: 'row',
        border: '1px dashed gray',
        padding: '0.5rem 1rem',
        cursor: 'move',
        opacity: isDragging ? 0.5 : 1,
        background: isActive ? '#ddd' : '',
        justifyContent: 'center',
    };

    return (
        <Cell {...rest} style={{ padding: 0 }}>
            <div ref={ref} style={styles}>
                {children}

            </div>
        </Cell>
    );
}


export class Grid extends React.PureComponent<any, {}> {
    public state: any = {
        isLoading: true,
        isRead: false,
        isModalOpen: false,
        isSettingsModalOpen: false,
        isEdit: null,
        form: {},
        totalWidth: 0,
        hiddenRows: [],
        selectedValues: {},
        searchs: {},
        columnOrder: JSON.parse(localStorage.getItem("column-order-" + this.props.name + "-" + this.props.globals.table) ?? "[]"),
        items: _.cloneDeep(this.props.values ?? []),
        isConfirmDeleteModal: false,
    };

    public elementRef: any = null;

    componentDidMount() {
        this.elementRef = React.createRef();
        var api = this.props.field.api.split("_").join("-");
        axios
            .get("/api/v1/" + api + "/fields")
            .then((res) => {
                if (res && res.data && res.data.fields) {
                    var fields = res.data.fields;
                    if (this.props.formOverrides) {
                        for (var i in this.props.formOverrides) {
                            fields[i] = { ...fields[i], ...this.props.formOverrides[i] };
                        }
                    }
                    this.setState({ form: fields }, this.loadCustoms);
                }
            })
            .finally(() => {
                this.setState({ isLoading: false });
            });
    }

    loadCustoms = () => {
        // if (this.state.form !== state.form) {

        const $GLOBALS = this.state.form?.$GLOBALS;
        const eventHandlerName = _.upperFirst(_.camelCase($GLOBALS?.prefix)) + "Events";
        // alert(eventHandlerName);
        const customList: any = CustomExtensions;

        if (customList[eventHandlerName]) {
            this.eventHandler = new customList[eventHandlerName]();
        }
        // }
    };

    renderSubmit = (onSubmitEvent: any) => {
        // return null;
        return (
            <div className="col-md-12" style={{ marginBottom: 10 }}>
                <Button
                    onClick={() => {
                        sessionStorage.removeItem("currentParent");
                        sessionStorage.removeItem("currentParentIndex");
                        this.setState({ isModalOpen: false });
                    }}>
                    <i className="fas fa-fw fa-times mr-2"></i>
                    Cancelar
                </Button>
                {this.state.isEdit !== undefined && this.state.isRead === false && (
                    <Button
                        color="green"
                        onClick={() => {
                            sessionStorage.removeItem("currentParent");
                            sessionStorage.removeItem("currentParentIndex");
                            if (onSubmitEvent) {
                                onSubmitEvent();
                            }
                        }}>
                        <i className="fas fa-fw fa-save mr-2"></i>
                        Gravar
                    </Button>
                )}
            </div>
        );
    };

    public cachedApiItems: any = {};

    onSubmit = async (values: any) => {
        for (var fieldName in values) {
            var value = values[fieldName];
            var field = this.state.form[fieldName];
            if (field) {
                if (field.type === "select" && value !== undefined && (field.api !== undefined || field.displayLabel !== undefined)) {
                    if (typeof value == "object") {
                        if (value?.["value"]) {
                            //delcio
                            values[fieldName] = value["value"];
                            value = values[fieldName];
                        }
                        // alert(JSON.stringify(value));
                    }
                    if (field.api && field.api.url) {
                        var url = field.api.url;
                        if (!this.cachedApiItems[url]) {
                            this.cachedApiItems[url] = {};
                        }
                        try {
                            var result = await axios.get(field.api.url.split("/select").join("/" + value), { params: { cache: true } });
                            if (result && result.data && result.data.status === 200) {
                                this.cachedApiItems[url][value] = result.data.item;
                                values[field.displayLabel.split(".").shift()] = result.data.item;
                            }
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }
            }
        }
        
        if(this.props.draggable) {
            if(this.state.items.length > 0) {
                const valuesItems = _.orderBy(this.state.items,[`${this.state.form?.$GLOBALS.prefix}_order_by`],'asc')
                const lastItemArray = valuesItems.pop()[`${this.state.form?.$GLOBALS.prefix}_order_by`]
                values[`${this.state.form?.$GLOBALS.prefix}_order_by`] = lastItemArray +1
            }else {
                values[`${this.state.form?.$GLOBALS.prefix}_order_by`] = 1
            }
        }

        this.setState(
            (oldState: any) => {
                var items = [...oldState.items];
                // //(values, this.state.isEdit);
                // //(this.state.form);

                if (this.state.isEdit !== null) {
                    items[this.state.isEdit] = values;
                } else {
                    items.push(values);
                }
                return { items, isModalOpen: false };
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange(this.state.items);
                }
                if (this.state.isEdit !== null) {
                    if (this.props.onEdit) {
                        this.props.onEdit(values, values.length);
                    }
                } else {
                    if (this.props.onAdd) {
                        this.props.onAdd(values, values.length);
                    }
                }
            },
        );
    };

    private eventHandler: any | null = null;

    componentDidUpdate(props: any, state: any) {
        if (this.elementRef && this.elementRef.current) {
            if (this.state.totalWidth === 0 && this.elementRef.current.clientWidth > 0) {
                this.setState({ totalWidth: this.elementRef.current.clientWidth });
            }
        }
        this.loadCustoms();
        // if(this.state.form !== this.state.visibleColumns) {
        //     this.setState({ visibleColumns: this.state.form });
        // }
    }

    onExit = (field: string, value: string, operator: string) => {
        this.setState({ isLoading: true });
        var searchs = this.state.searchs;
        if (value === undefined) {
            delete searchs[field];
        } else {
            searchs[field] = value;
        }
        this.setState({ searchs: searchs }, () => {
            this.setState({ isLoading: false });
            // //("NEW STATE",this.state.searchs);
        });
        // alert("EXITED");
    };

    renderColumns = () => {
        var output = [];
        var fields = Object.keys(this.state.form);
        //Alteração nas ordens das colunas nos grids
        var form: any = _.filter(
            // _.sortBy(Object.values(this.state.form), (item: any) => {

            //     return this.state.columnOrder.indexOf(item.name);
            // }),
            // (item: any) => {
            //     return item.label?.length > 0;
            // },
            _.orderBy(this.state.form, "browserOrder", "asc"), (item) => {
                return item.label?.length > 0;
            }
        );
        for (var i in form) {
            let field = form[i];
            // let width = _.clamp((this.state.totalWidth - 130) / (fields.length - 1), field.name?.length * 9.6, this.state.totalWidth);
            let width = field.browserWidth ? (field.browserWidth * 100) : _.clamp((this.state.totalWidth - 130) / (fields.length - 1), field.name?.length * 9.6, this.state.totalWidth);

            if (i !== "$GLOBALS" && field.canBrowse !== false) {
                output.push(
                    <Column resizable key={i} width={width}>
                        <HeaderCell className="filterable">
                            <FilterableCell field={field} hasAdvanced={false} onExit={(value: any, operator: any) => this.onExit(field.name, value, operator)}>
                                <div dangerouslySetInnerHTML={{ __html: field.label ?? field.name }}></div>
                            </FilterableCell>
                        </HeaderCell>
                        <Cell>
                            {(row: any) => {
                                return <SchemaToParsedCell row={row} field={field} />;
                            }}
                        </Cell>
                    </Column>,
                );
            }
        }
        return output;
    };

    filterData = (searchs: any) => {
        var output = [];

        var data = this.state.items;
        for (var i in data) {
            var row = data[i];

            if (this.state.hiddenRows.includes(i)) {
                continue;
            }
            var includes = true;
            for (var fieldName in this.state.form) {
                var field = this.state.form[fieldName];
                if (field !== undefined) {
                    var parsedValue = SchemaToParsedCell.parseFieldToValue(field, row);
                    if (searchs[fieldName]) {
                        if (!parsedValue.includes(searchs[fieldName])) {
                            includes = false;
                            break;
                        }
                    }
                }
            }
            // //("PARSED",SchemaToParsedCell.parseFieldToValue(this.state.form[i],data[i]))
            if (includes) {
                output.push(data[i]);
            }
        }
        if(this.props.draggable) {
            output = _.orderBy(output,[`${this.state.form?.$GLOBALS.prefix}_order_by`],'asc')
        }
        return output;
    };
    onConfirmDelete = async (rowData: any, rowIndex: any, currentTable: any) => {
        // const currentTable = this.props.field['name'];
        axios.post("/api/v1/perm-delete", { id: rowData[pluralize.singular(currentTable) + "_id"], currentTable: currentTable }).then((res) => {
            Notification.error({
                title: "Delete",
                description: "Item Deletado"
            })
        })

        var items = this.state.items;
        items.splice(rowIndex, 1);
        this.setState({ items, isLoading: true }, () => {
            if (this.props.onChange) {
                this.props.onChange(items);
            }
            if (this.props.onDelete) {
                this.props.onDelete(items);
            }
            this.setState({ isLoading: false, isConfirmDeleteModal: false });
            this.setState({ tempDeleteItemID: null, tempDeleteItem: null, tempDeleteTable: null });
        });
    }
    onClickDelete = async (rowIndex: any, rowData: any) => {
        // console.log({rowData})//delcio
        const currentTable = this.props.field['name'];
        this.setState({ isConfirmDeleteModal: true, tempDeleteItemID: rowIndex, tempDeleteItem: rowData, tempDeleteTable: currentTable });

        // axios.post("/api/v1/perm-delete",{id:rowData[pluralize.singular(currentTable)+"_id"],currentTable:currentTable}).then((res)=> {
        //     Notification.error({
        //         title: "Delete",
        //         description:"Item Deletado"
        //     })
        // })

        // var items = this.state.items;
        // items.splice(rowIndex, 1);
        // this.setState({ items, isLoading: true }, () => {
        //     if (this.props.onChange) {
        //         this.props.onChange(items);
        //     }
        //     if (this.props.onDelete) {
        //         this.props.onDelete(items);
        //     }
        //     this.setState({ isLoading: false });
        // });
    };

    renderActions = (rowData: any, rowIndex: any) => {
        const extras = this.eventHandler?.getActions ? this.eventHandler?.getActions(rowData) : null;
        // var idColumnName = this.props.form.$GLOBALS.prefix.toLocaleLowerCase() + "_id";
        return (
            <div style={{ display: "flex" }}>
                {this.props.actions ? this.props.actions(rowData) : null}
                <i onClick={() => this.setState({ isRead: true, isModalOpen: true, selectedValues: rowData, isEdit: null })} className="fas text-primary fa-fw mr-2 clickable fa-eye"></i>
                {this.props.readOnly !== true && this.props.canEdit !== false && (
                    <i
                        onClick={() => this.setState({ isRead: false, isModalOpen: true, selectedValues: rowData, isEdit: rowIndex })}
                        className="fas text-warning fa-fw mr-2 clickable fa-pencil-alt"></i>
                )}
                {this.props.readOnly !== true && this.props.canDelete !== false && (
                    <i onClick={() => this.onClickDelete(rowIndex, rowData)} className="fas text-danger fa-fw mr-2 clickable fa-trash"></i>
                )}
                {extras}
                <Modal show={this.state.isConfirmDeleteModal}>
                    <Modal.Header closeButton={false}>
                        <Modal.Title>
                            <i className="fas fa-fw mr-2 fa-exclamation-triangle" style={{ color: "#ffb300", fontSize: 24 }}></i>
                            Atenção!
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        {this.state.isLoading && <Loader size="md" />}
                        {!this.state.isLoading && <>Deseja mesmo excluir esse registro ? o registro não poderá ser restaurado!</>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button disabled={this.state.isLoading} appearance="primary" onClick={() => this.onConfirmDelete(this.state.tempDeleteItem, this.state.tempDeleteItemID, this.state.tempDeleteTable) /*this.onConfirmDelete(this.state.tempDeleteRowItem, this.state.tempDeleteItemID,this.state.tempDeleteTable)*/}>
                            <i className="fas fa-check fa-fw mr-2"></i>Confirmar
                        </Button>
                        <Button disabled={this.state.isLoading} color="red" onClick={() => this.setState({ isConfirmDeleteModal: false })}>
                            <i className="fas fa-times fa-fw mr-2"></i>Cancelar
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };

    openAdd = () => {
        const values = this.props.parentValues;
        sessionStorage.setItem("currentParent", JSON.stringify(values));
        sessionStorage.setItem("currentParentIndex", values[this.props.globals.prefix + "_id"]);
        this.setState({ isRead: false, isModalOpen: true, isEdit: null, selectedValues: {} });
    };

    onSort = (columnOrder: any) => {

        // alert(JSON.stringify(visibleColumns))
        localStorage.setItem("column-order-" + this.props.name + "-" + this.props.globals.table, JSON.stringify(columnOrder));
        this.setState({ columnOrder });
    };

    getSortableData = () => {
        const output = [];
        var items = [];
        if (this.state.columnOrder.length > 0) {
            items = _.filter(
                _.orderBy(this.state.form, (item) => {
                    return this.state.columnOrder.indexOf(item.name);
                }),
                (item) => {
                    return item.label?.length > 0 && item.canBrowse == true;
                },
            );
        } else {
            items = _.filter(_.orderBy(this.state.form, "browserOrder", "asc"), (item) => {
                return item.label?.length > 0;
            });
        }

        for (var i in items) {
            output.push({
                ...items[i],
            });
        }
        return output;
    };


    sort(source: any, sourceId: any, targetId: any) {
        const columnIdName = `${this.state.form?.$GLOBALS?.prefix}_order_by`
        const nextData = source.filter((item: any) => item[`${columnIdName}`] !== sourceId);
        const dragItem = source.find((item: any) => item[`${columnIdName}`] === sourceId);
        const index = nextData.findIndex((item: any) => item[`${columnIdName}`] === targetId);
        const sourceItem = source.find((item: Record<string,any>) => item[`${columnIdName}`] === targetId);

        dragItem[`${columnIdName}`] = targetId;
        sourceItem[`${columnIdName}`] = sourceId;
        nextData.splice(index + 1, 0, dragItem);

        return nextData;
    }

    handleDragRow = (sourceId: any, targetId: any) => {
        const sourceFilter = this.filterData(this.state.searchs);
        const newArrayDraggle = this.sort(sourceFilter, sourceId, targetId);
        this.setState({ items:  newArrayDraggle });
        this.props.onChange(newArrayDraggle);
    };

    render() {
        if (this.props.inline === true) {
            return <InlineGrid {...this.props} />;
        }
        var $GLOBALS = this.state.form?.$GLOBALS;

        var data: any = [];
        if (this.state.isLoading) {
            data = [];
        } else {
            data = this.filterData(this.state.searchs);
        }
        const rowSize = this.props.compact ? 25 : (this.props.draggable ? 60 : undefined) ;
        return (
            <div style={{ display: "flex", flexDirection: "column" }} ref={this.elementRef}>
                {!this.state.isLoading ? (
                    <Panel bordered bodyFill style={{ padding: 8 }}>
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: 8 }}>
                            {this.props.compact !== true && <h3 dangerouslySetInnerHTML={{ __html: $GLOBALS?.plural_name ?? this.props.label ?? "" }}></h3>}
                            {this.props.compact === true && <h5 dangerouslySetInnerHTML={{ __html: $GLOBALS?.plural_name ?? this.props.label ?? "" }}></h5>}
                            <div>
                                <Button onClick={() => this.setState({ isSettingsModalOpen: true })} loading={!(Object.keys(this.state.form).length > 0)} className="mr-4">
                                    <i className="fas fa-fw fa-cog mr-2"></i>
                                    Configurações
                                </Button>
                                {this.props.canAdd === true && this.props.readOnly !== true && (
                                    <Button color="green" onClick={this.openAdd}>
                                        <i className="fas fa-fw fa-plus mr-2"></i>
                                        Adicionar
                                    </Button>
                                )}
                            </div>
                        </div>
                        <Modal size={"sm"} show={this.state.isSettingsModalOpen} overflow={true}>
                            <Modal.Header onHide={() => this.setState({ isSettingsModalOpen: false })}>Ordenação</Modal.Header>
                            <Modal.Body>
                                <div className="row">
                                    {!this.state.isLoading && (
                                        <div className="col-md-12">
                                            <SortableArray onChange={this.onSort} items={this.getSortableData()} />
                                        </div>
                                    )}
                                    {this.state.isLoading && (
                                        <div className="col-md-12 d-flex justify-content-center">
                                            <Loader size={"md"} />
                                        </div>
                                    )}
                                </div>
                                {/* {JSON.stringify(this.state.columnOrder)} */}
                            </Modal.Body>
                            <Modal.Footer>
                                <Button onClick={() => this.setState({ isSettingsModalOpen: false })}>Fechar</Button>
                            </Modal.Footer>
                        </Modal>
                        <Modal overflow={false} size={this.props.field.size ? this.props.field.size : "lg"} className="rs-modal-min" show={this.state.isModalOpen}>
                            {Object.keys(this.state.form).length > 0 && (
                                <Formalize
                                    isEdit={this.state.isEdit}
                                    isModal={true}
                                    readOnly={this.state.isRead}
                                    parentValues={this.props.parentValues}
                                    parent={this.props.parent}
                                    values={_.cloneDeep(this.state.selectedValues)}
                                    onSubmit={this.onSubmit}
                                    submitButton={this.renderSubmit}
                                    form={this.state.form}
                                    history={this.props.history}
                                    name={this.props.field.api}
                                />
                            )}
                        </Modal>

                        <DndProvider backend={HTML5Backend}>
                            <Table
                                headerHeight={rowSize}
                                rowHeight={rowSize}
                                className={this.props.compact ? "compact" : ""}
                                renderEmpty={() => <div className="rs-table-body-info">Nenhum item encontrado.</div>}
                                loading={this.state.isLoading}
                                height={400}
                                data={data}
                            >
                                {(this.props.draggable && $GLOBALS?.prefix !== undefined) && (
                                    <Column fixed={"left"} width={this.eventHandler?.width ?? 100} >
                                        <HeaderCell>Ordem</HeaderCell>
                                        <DraggableCell onDrag={this.handleDragRow} columnIdName={`${$GLOBALS?.prefix}_order_by`}>
                                            <i className="fas fa-arrows-alt"></i>
                                        </DraggableCell>
                                    </Column>
                                )}
                                {this.renderColumns()}
                                <Column fixed={"right"} width={this.eventHandler?.width ?? 100}>
                                    <HeaderCell>Ações</HeaderCell>
                                    <Cell className="link-group">
                                        {(rowData: any, rowIndex: any) => {
                                            return this.renderActions(rowData, rowIndex);
                                        }}
                                    </Cell>
                                </Column>
                            </Table>
                        </DndProvider>
                    </Panel>
                ) : (
                    <Placeholder.Grid rows={5} columns={6} active />
                )}
                {/* {JSON.stringify(this.state.selectedValues)} */}
                {/* {JSON.stringify(this.props.globals)} */}
                {/* {this.props.readOnly ? 'S': 'N'} */}
                {/* {JSON.stringify(this.state.searchs)} */}
                {/* {JSON.stringify(this.state.items)} */}
            </div>
        );
    }
}
