import { Event } from "../Event";
import axios from "../../utilities/axios";
import dayjs from "dayjs";
import { Notification, Alert } from "rsuite";

export class TransferRequestEvents extends Event {
  public onLoad = async (value: any, setField: any, setState: any, setFieldValue: any, formValues: any) => {




    const userInfo: any = await axios
      .get("/api/v1/me")
      .then((subRes) => {
        return subRes
      })
      .catch((error) => { })
      .finally(() => {

      });


    const UserInfoChecked = userInfo.data.user
    if (UserInfoChecked?.user_blocked == true) {
      Alert.error('Usuário bloqueado, contato o administrador do sistema!', 30000)

      console.log({ UserInfoChecked })

      const blockedUserFields: any = ['transfer_request_fk_user_id', 'transfer_request_collect_fk_user_id', 'transfer_request_serie', 'transfer_request_nf_value'
        , 'transfer_request_nf_number', 'transfer_request_collect_fk_user_id', 'transfer_request_quantity_volumes']
      this.setFieldValue('transfer_request_fk_status_transfer_request_id', 1)

      for (const i in blockedUserFields) {
        this.setField(blockedUserFields[i], {
          readonly: true
        })
      }

      this.setField('item_requests', {
        gridAdd: false
      })
    }

    if (this.globalProps.match.url.includes('/add')) {
      // console.log('Oiiiiie')
      // if (UserInfoChecked?.user_blocked !== true) {
      //   this.setFieldProperty('transfer_request_collect_fk_user_id', 'options', []);
      //   await axios
      //     .get('/api/v1/transfer-request-get/select', {
      //       params: {
      //         storeRequestId: UserInfoChecked?.user_id
      //       }
      //     })
      //     .then((response) => {
      //       this.setFieldProperty('transfer_request_collect_fk_user_id', 'options', response.data.items)
      //     })

      // }

      if (UserInfoChecked?.user_fk_role_id == 17 && UserInfoChecked?.user_blocked !== true) {

        this.setState("loadings", ['transfer_request_fk_user_id', 'transfer_request_collect_fk_user_id', 'transfer_request_fk_service_type_id',
          'transfer_request_nf_number', 'transfer_request_serie', 'transfer_request_creation_date', 'transfer_request_fk_status_transfer_request_id', 'transfer_request_quantity_volumes'])

        this.setField("transfer_request_fk_user_id", {
          readonly: true
        })

        this.setField('transfer_request_collect_fk_user_id', {
          readonly: false
        })

        this.setField('transfer_request_fk_service_type_id', {
          readonly: false
        })

        this.setField('transfer_request_nf_value', {
          readonly: false
        })


        this.setFieldValue('transfer_request_fk_status_transfer_request_id', 1)
        this.setFieldValue('transfer_request_fk_service_type_id', 2)
        this.setFieldValue('transfer_request_fk_user_id', UserInfoChecked?.user_id)
        this.setFieldValue("transfer_request_creation_date", dayjs().format('DD/MM/YYYY HH:mm:ss'))

        setTimeout(() => {
          this.setState({ loadings: [] });
        }, 5000);


      } else {
        if (UserInfoChecked?.user_blocked !== true) {
          this.setState("loadings", ['transfer_request_fk_user_id', 'transfer_request_collect_fk_user_id', 'transfer_request_fk_service_type_id', 'transfer_request_creation_date', 'transfer_request_fk_status_transfer_request_id'])

          this.setFieldValue('transfer_request_fk_service_type_id', 2)
          this.setFieldValue('transfer_request_fk_user_id', UserInfoChecked?.user_id)
          this.setFieldValue("transfer_request_creation_date", dayjs().format('DD/MM/YYYY HH:mm:ss'))
          axios.get('/api/v1/code')
            .then((res) => {
              this.setFieldValue("transfer_request_code", res.data.data)
            })
            .catch((e) => {
              console.log("erro code", e)

            })
          setTimeout(() => {
            this.setState({ loadings: [] });
          }, 3000);



          const enabledFields: any = ['transfer_request_fk_service_type_id', 'transfer_request_collect_fk_user_id', 'transfer_request_fk_user_id',
            'transfer_request_nf_value', 'transfer_request_quantity_volumes']
          this.setFieldValue('transfer_request_fk_status_transfer_request_id', 1)
          for (const i in enabledFields) {
            this.setField(enabledFields[i], {
              readonly: false
            })
          }
        }


      }
    } else {
      if (UserInfoChecked?.user_blocked !== true) {

        const enabledFields: any = ['transfer_request_fk_service_type_id', 'transfer_request_collect_fk_user_id', 'transfer_request_fk_user_id', 'transfer_request_nf_number',
          'transfer_request_nf_value', 'transfer_request_serie', 'transfer_request_quantity_volumes']
        for (const i in enabledFields) {
          this.setField(enabledFields[i], {
            readonly: false
          })
        }
      }
    }

    this.setState('loadings', [])







    if (this.globalProps.match.url.includes('/view')) {
      this.setField("transfer_request_code", {
        readonly: true,
        hidden: false,
        width: 2,
      })
      this.setField("transfer_request_fk_status_transfer_request_id", {
        readonly: true,
        hidden: false,
        width: 3,
        order: 11,
        label: "Situação Atual"
      })
      this.setField("transfer_request_fk_service_type_id", {
        readonly: true,
        hidden: false,
        width: 5
      })
      this.setField("transfer_request_collect_fk_user_id", {
        readonly: true,
        hidden: false,
        width: 6
      })
      this.setField("transfer_request_fk_user_id", {
        readonly: true,
        hidden: false,
      })
      this.setField("transfer_request_creation_date", {
        readonly: true,
        hidden: false,
        width: 2
      })
    }

  }
}