import { Event } from "../Event";
import axios from "../../utilities/axios";
import { Notification } from "rsuite";

export class TransferRequestSerieEvents extends Event {
  onBlur = (value: any, setField: any, setState: any, formValues: any) => {
    const formattedValue = ("000" + value).slice(-3).replace(/(\d{3})/g, '$1 ').trim();

    this.setFieldValue("transfer_request_serie", formattedValue)
  }
}