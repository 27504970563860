// budget_fk_charging_type_id

import dayjs from "dayjs";
import axios from "../../utilities/axios";
import { Event } from "../Event";
import { Notification } from "rsuite";

export class BudgetFkChargingDescriptionIdEvents extends Event {
    public onChange = async (value: any, setField: any, setState: any, formValues: any) => {
        this.setState('loadings', [
            'budget_is_chemical',
            'budget_fk_chemical_class_id'
        ])

        if (value == 7 || value == 6) {
            this.setFieldValue('budget_is_anvisa', true)
            this.setField('budget_fk_chemical_class_id', {
                readonly: false,
                required: true
            })
        }

        this.setState('loadings', [])
    }
}

